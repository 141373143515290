// import React from "react";
import Cookies from "js-cookie";
import { React, useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import yPlay from "../Assets/yellow_play.png";
import Typography from '@mui/material/Typography'

function WelcomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  let [selectedLanguage, setSelectedLanguage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // handleLanguageChange("English");

    console.log(`${selectedLanguage} new data`);
    let ignore = false;
    if (!ignore) {
      var a = localStorage.getItem("lang");
      if (a != null) {
        setSelectedLanguage(JSON.parse(a));
      } else {
        setSelectedLanguage("English");
    handleLanguageChange("English");
      
      }
      // console.log(`${a}`);
    }
    return () => {
      ignore = true;
    };
  }, []);
  useEffect(() => {
    const isFirstTime = Cookies.get("isFirstTime");
    if (!isFirstTime) {
      setShowModal(true);
      Cookies.set("isFirstTime", "false", { expires: 365 }); // Cookie expires in a year
    }
  }, []);

  const closeModalFirstTime = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const saveToLocal = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));
    var a = localStorage.getItem("lang");

    // console.log(a);
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem("language", JSON.stringify(language));
    setSelectedLanguage(language);
    saveToLocal(language);
    closeModal();
  };

  return (
    <div className="mainCon">
      <div>
        <button className="langOpen" onClick={openModal}>
          {localStorage.getItem('language')}
        </button>

        <Modal
          isOpen={showModal}
          onRequestClose={closeModalFirstTime}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              width: "200px",
            },
          }}
        >
          <p className="first-time-text">
          Remember to set your language before to start using funfy
          </p>
          <button className="first-time-alert" onClick={closeModalFirstTime}>
            OK
          </button>
        </Modal>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              width: "200px",
            },
          }}
        >
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("English")}
          >
            {selectedLanguage === "English" ? "English" : "English"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Italiano")}
          >
            {selectedLanguage === "Italiano" ? "Italiano" : "Italiano"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Spagnolo")}
          >
            {selectedLanguage === "Spagnolo" ? "Spagnolo" : "Spagnolo"}
          </button>
        </Modal>
      </div>
      <div className="logoCon">
        <img className="logo" src={logo} alt="logo" />
      </div>
    
<Typography fontSize={20}   fontWeight={600} variant="body1" width={300} textAlign={'center'} color="initial"  id="my-text">
The deepest level of
collaborative artistic work
needs your voice!
</Typography>
     
      <Link onClick={async () => {}} to="/home" >
        <div className="nextBtn">
          <img className="playIcon" src={yPlay} alt="play" />
        </div>
      </Link>
    </div>
  );
}

export default WelcomePage;
