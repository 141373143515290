import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { ReactMic } from "react-mic";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { RWebShare } from "react-web-share";
import Pause from "../Assets/Pause.svg";
import bin from "../Assets/bin.png";
import coffee from "../Assets/coffee.png";
import lock from "../Assets/lock_audio.png";
import logo from "../Assets/logo.png";
import more from "../Assets/menu.png";
import recordIcon from "../Assets/mic.png";
import recordIcon3 from "../Assets/mic2.svg";
import forward from "../Assets/next_audio.png";
import pause from "../Assets/pause.png";
import send from "../Assets/play.png";
import reportIcon from "../Assets/report.png";
import share from "../Assets/share.png";
import blinking from "../Assets/system-solid-77-pause.gif";
import recordIcon2 from "../Assets/wired-lineal-188-microphone-recording.gif";
import mic from "../Assets/yellow_mic.png";
import play from "../Assets/yellow_play.png";
import { globalIP } from "../Ip_address";

function HomePage() {
  
  //temporary
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    // isRecording,
    // isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();

  useEffect(() => {
    if (!recordingBlob) return;
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob]);
  if (recordingBlob) {
  }

  const recorderControls = useAudioRecorder();
  let isDataLoading = true; // Assume initially data is loading

  const [language, setLanguage] = useState("English");
  let [fetchAudio, setFetchAudio] = useState();
  let [AudioOn, setAudioOn] = useState(false);
  let [recordIconCondition, setRecordIconCondition] = useState(false);
  let [noAudioFound, setNoAudioFound] = useState(false);
  let [limitTime, setLimitTime] = useState();
  let [autoPlayCondtion, setAutoplayCondition] = useState(false);
  let [currentAudioId, setCurrentAudio] = useState();
  let listData = [];
  // let [currentAudio, seCurrentAudio] = useState(
  //   "https://funfy.org/server/en/funfy-179-wefew-182.191.35.18.mp3"
  // );
  let [currentAudio, seCurrentAudio] = useState(
    "https://funfy.org/server/en/funfy-287-wefew-119.156.155.199.mp3"
  );
  let [currentBlob, setCurrentBlob] = useState();
  let [currentIndex, setCurrentIndex] = useState(0);
  let [headingText, setHeadingText] = useState(
    "Listen\nContribute Record your message be funny be funfy!"
  );
  let [headingTextOne, setHeadingTextOne] = useState("Listen");
  let [headingTextTwo, setHeadingTextTwo] = useState("Contribute");
  let [headingTextThree, setHeadingTextThree] = useState(
    "be part of global digital art"
  );
  let [headingTextFour, setHeadingTextFour] = useState("Record your message");
  
  let [selectedLanguage, setSelectedLanguage] = useState("");

  // replay functionality

  const [isRecordingTwo, setIsRecordingTwo] = useState(false);
  const [isPausedTwo, setIsPausedTwo] = useState(false);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const audioRef = useRef(null);

  let [audioBlob, setAudioBlob] = useState(null);
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      isComingFromSharedLink();
      var a = localStorage.getItem("lang");
      selectedLanguage = a;
      setSelectedLanguage(a);
    }
    return () => {
      ignore = true;
    };
  }, [currentAudio]);
  const reportAudio = async () => {
    var raw = JSON.stringify({
      audio_id: parseInt(currentAudioId),
      ip_address: ip,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await fetch(`https://${globalIP}/api/v1/reportVoiceMessage`, requestOptions)
      .then(async (response) => {
        var data = await response.json();
      })

      .then((result) => {})
      .catch((error) => {});
  };

  const indexchnager = () => {
    var b = (currentIndex += 1);
    setCurrentIndex(b);
    setIsPlay(false);
    getVoice();
  };

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  const createBlob = async () => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", currentAudio, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      if (this.status == 200) {
        var myBlob = this.response;
        setBlob(myBlob);
        blob = myBlob;
      }
    };
    xhr.send();
    setTimeout(() => {
      if (currentAudio != null) {
        setAudioOn(true);
      } else {
        msgFunc("No Audio Found!");
      }
    }, 2000);
  };
  const getTimer = async () => {
    await axios
      .get(`https://${globalIP}/api/v1/getAppConfig`)
      .then(function (response) {
        setLimitTime(response.data.config[0].value);
        limitTime = response.data.config[0].value;
      })
      .catch(function (error) {
        msgFunc("Network Error");
      })
      .finally(function () {});
  };
 
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // const getVoice = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Cookie",
  //     "uads=a%3A2%3A%7Bs%3A4%3A%26quot%3Bdate%26quot%3B%3Bi%3A1692906205%3Bs%3A5%3A%26quot%3Buaid%26quot%3B%3Ba%3A0%3A%7B%7D%7D; mode=night; src=1; user_id=11feaf01c8a0a60d73685aed9f29d0055654b7031692820141a226f6eb33e24c201535c4f106b5d4e9"
  //   );
  //   var languageCode = 'es'; 

  //   if (selectedLanguage === 'Spagnolo') {
  //     languageCode = 'es';
  //   } else if (selectedLanguage === 'Italiano') {
  //     languageCode = 'it';
  //   }
  //   var raw = JSON.stringify({
  //     language: languageCode,
  //   });
    
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const audioExtension = isSafari ? "wav" : "mp3";

  //   await fetch(`https://${globalIP}/api/v1/getVoiceMessages/`, requestOptions)
  //     .then(async (response) => {
  //       var data = await response.json();
  //       listData = await data.audio;

  //       if (IsComingFromShareLink) {
  //         for (let index = 0; index < listData.length; index++) {
  //           if (listData[index]["id"] === parseInt(searchAudioId)) {
  //             currentAudioId = listData[index]["id"];
  //             setCurrentAudio(listData[index]["id"]);
  //             currentAudio = `${listData[index]["file_name"].replace(
  //               /\.\w+$/,
  //               `.${audioExtension}`
  //             )}`;
  //             if (currentAudio == null) {
  //               msgFunc("Audio is Missing!");
  //             }
  //           }
  //         }
  //         if (currentAudio != null) {
  //           createBlob();
  //         }
  //       } else {
  //         currentAudio = `${listData[currentIndex]["file_name"].replace(
  //           /\.\w+$/,
  //           `.${audioExtension}`
  //         )}`;
  //         seCurrentAudio(
  //           `${listData[currentIndex]["file_name"].replace(
  //             /\.\w+$/,
  //             `.${audioExtension}`
  //           )}`
  //         );
  //         currentAudioId = listData[currentIndex]["id"];
  //         setCurrentAudio(listData[currentIndex]["id"]);
  //         if (currentAudio != null) {
  //           createBlob();
  //         }
  //       }
  //       setNoAudioFound(false);
  //     })
  //     .catch((error) => {
  //       setNoAudioFound(true);
  //       msgFunc("No Audio Here!");
  //     });
  // };

  const getVoice = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "uads=a%3A2%3A%7Bs%3A4%3A%26quot%3Bdate%26quot%3B%3Bi%3A1692906205%3Bs%3A5%3A%26quot%3Buaid%26quot%3B%3Ba%3A0%3A%7B%7D%7D; mode=night; src=1; user_id=11feaf01c8a0a60d73685aed9f29d0055654b7031692820141a226f6eb33e24c201535c4f106b5d4e9"
    );
  
    var languageCode = 'defined'; // xxxxx
    var langii = JSON.parse(localStorage.getItem('language')) // xxxxx
    
    if (langii === 'Spagnolo') {
      languageCode = 'es';
    } else if (langii === 'Italiano') {
      languageCode = 'it';
    }else if (langii === "English"){
      languageCode = 'en'
    }
    var raw = JSON.stringify({
      language: languageCode,
    });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    const audioExtension = isSafari ? "wav" : "mp3";
    try {
      const response = await fetch(`https://${globalIP}/api/v1/getVoiceMessages/`, requestOptions);
      if (response.ok) {
        const data = await response.json();
        listData = data.audio;
  
        // Logic to check for available audio in the selected language
        const availableAudio = listData.find(audio => audio.audio_language === languageCode);
  
        if (availableAudio) {
          // Play the available audio
          currentAudio = `${availableAudio.file_name.replace(/\.\w+$/, `.${audioExtension}`)}`;
          setCurrentAudio(availableAudio.id);
          createBlob();
          setNoAudioFound(false);
        
          // Autoplay the audio after a delay
          const audioPlayer = document.getElementById('audioPlay'); // Replace 'audioPlayer' with your audio element ID
          if (audioPlayer) {
            audioPlayer.src = currentAudio;
            audioPlayer.controls = true;
        
            setTimeout(() => {
              audioPlayer.autoplay = true;
            }, 3000); // Adjust the delay time as needed (e.g., 3000 milliseconds for 3 seconds)
          }
        } else {
          // No audio available for the selected language
          setNoAudioFound(true);
          msgFunc("No Audio Here!");
        }
      } else {
        setNoAudioFound(true);
        // msgFunc("Error fetching audio!");
      }
    } catch (error) {
      setNoAudioFound(true);
      msgFunc("Error: " + error.message);
    }
    
    // try {
    //   const response = await fetch(`https://${globalIP}/api/v1/getVoiceMessages/`, requestOptions);
    //   if (response.ok) {
    //     const data = await response.json();
    //     listData = data.audio;
  
    //     // Logic to check for available audio in the selected language
    //     const availableAudio = listData.find(audio => audio.audio_language === languageCode);
    //     if (availableAudio) {
    //       // Play the available audio
    //       currentAudio = `${availableAudio.file_name.replace(/\.\w+$/, `.${audioExtension}`)}`;
    //       setCurrentAudio(availableAudio.id);
    //       createBlob();
    //       setNoAudioFound(false);
    //     } else {
    //       // No audio available for the selected language
    //       setNoAudioFound(true);
    //       msgFunc("No Audio Here!");
    //     }
    //   } else {
    //     setNoAudioFound(true);
    //     msgFunc("Error fetching audio!");
    //   }
    // } catch (error) {
    //   setNoAudioFound(true);
    //   msgFunc("Error: " + error.message);
    // }
  };



  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [micAllowed, setMicAllowed] = useState(
    localStorage.getItem('micPermission') === 'granted'
  );
  
  useEffect(() => {
    checkMicPermission();
  }, []);
  
  const checkMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicAllowed(true);
      localStorage.setItem('micPermission', 'granted');
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      setMicAllowed(false);
      if (localStorage.getItem('micPermission') !== 'granted') {
        setModalIsOpen(true);
        setTimeout(() => {
          setModalIsOpen(false);
        }, 2000); // 2 seconds
      }
    }
  };
  
  const closeModalAlert = () => {
    setModalIsOpen(false);
  };
  
  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('micPermission');
  });
  

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      var a = localStorage.getItem("lang");
      selectedLanguage = a;
      setSelectedLanguage(a);
      getTimer();
      createBlob();
      isComingFromSharedLink();
      getIp();
      getVoice();
      if (selectedLanguage?.includes("Italiano")) {
        setHeadingText("Ascolta\nContribuisci fai parte dell'arte globale registra un messaggio");
        // headingText = "Ascolta\nContribuisci fai parte dell'arte globale registra un messaggio"; // Commented out, as it's redundant
        setHeadingTextOne("Ascolta");
        setHeadingTextTwo("Contribuisci");
        setHeadingTextThree("fai parte dell'arte globale");
        setHeadingTextFour("registra un messaggio");
        
      } else if (selectedLanguage?.includes("Spagnolo")) {
        setHeadingText("Escuchar graba tu mensaje ¡Sé divertido sé divertido!");
        headingText = "Escuchar graba tu mensaje ¡Sé divertido sé divertido!";
        headingTextOne =
        "Escuchar";
      setHeadingTextOne(
        "Escuchar"
      );
      headingTextTwo =
        "contribuye";
      setHeadingTextTwo(
        "contribuye"
      );
      headingTextThree =
        "sé parte del arte global ";
      setHeadingTextThree(
        "sé parte del arte global "
      );
      headingTextFour =
      "grabando un mensaje ";
    setHeadingTextFour(
      "grabando un mensaje "
    );
      } else {
        setHeadingText("Listen\nContribute be part of global digital art record your message");
        headingText = "Listen\nContribute  be part of global digital art record your message";
        headingTextOne =
        "Listen";
      setHeadingTextOne(
        "Listen"
      );
      headingTextTwo =
        "Contribute";
      setHeadingTextTwo(
        "Contribute"
      );
      headingTextThree =
        "be part of global digital art";
      setHeadingTextThree(
        "be part of global digital art"
      );
      headingTextFour =
      "record your message";
    setHeadingTextFour(
      "record your message"
    );
      }
    }
    return () => {
      ignore = true;
    };
  }, []);
  const [stream, setStream] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  let [lockBar, setLockBar] = useState(false);
  let [msgShow, setMsgShow] = useState(false);
  const [recordBar, setRecordBar] = useState(false);
  const [dragPosition, setDragPosition] = React.useState(null);
  const [alertMsg, setAlertMsg] = useState("Abuse Reported");
  let [blob, setBlob] = useState();
  let [newAudioBlob, setNewAudioBlob] = useState();
  const recorder = useAudioRecorder();
  const [value, setValue] = useState(0);
  const [ip, setIP] = useState("");
  const [isDraggingUp, setIsDraggingUp] = useState(false);
  const buttonRef = useRef(null);
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const randomString =
    Math.random().toString(20).substring(2, 14) +
    Math.random().toString(20).substring(2, 14);
  const deviceID = `${userAgent}-${platform}-${randomString}`;
  const msgFunc = (msg) => {
    setAlertMsg(msg);
    setMsgShow(true);
    sleep(1500).then(() => {
      setMsgShow(false);
    });
  };
  const [isTimeLimitCrossed, setTimeLimitCrossed] = useState(false);

  useEffect(() => {
    if (recorder.recordingTime > limitTime && !isTimeLimitCrossed) {
      recorder.togglePauseResume();
      setTimeLimitCrossed(true);
      setRecordIconCondition(true);
      msgFunc("Time Limit Crossed");
    }
  }, [recorder.recordingTime, limitTime, isTimeLimitCrossed]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleStartRecording = async (a) => {
    if (a.clientY < 550) {
      setRecordBar(true);
      setLockBar(false);
      return;
    } else {
      setRecordBar(false);
    }
    setLockBar(true);
    recorder.startRecording();
  };

  const handleStopRecording = async (a) => {
    if (a.clientY < 550) {
      return;
    }

    setLockBar(false);

    recorder.stopRecording();
    recorder.mediaRecorder.addEventListener("dataavailable", (d) => {
      if (d.data != null) {
      }
      setDuration(
        `${
          recorder.recordingTime < 60
            ? `00:${recorder.recordingTime < 10 ? "0" : ""}${
                recorder.recordingTime
              }`
            : `${Math.floor(recorder.recordingTime / 60)}:${
                recorder.recordingTime % 60 < 10 ? "0" : ""
              }${recorder.recordingTime % 60}`
        }`
      );
    });


    convertAudioToBase64(blob);
  };

  const handleDrag = (_, { deltaY }) => {
    // Handle drag events
    if (deltaY < -50) {
      // Adjust the threshold based on your preference
      setIsDraggingUp(true);
    } else {
      setIsDraggingUp(false);
    }
  };

  const handleDragStop = () => {
    setDragPosition({ x: 0, y: 0 });

    if (isDraggingUp) {
    }
    setIsDraggingUp(false);
  };

  async function convertAudioToBase64(fileInput) {
    const reader = new FileReader();

    if (fileInput != null) {
      reader.onload = function (event) {
        recorder.stopRecording();

        let data;
        if (event.target.result?.includes("webm")) {
          data = event.target.result?.replace("webm", "mpeg");
        } else if (event.target.result?.includes("m4a")) {
          data = event.target.result?.replace("m4a", "mpeg");
        } else if (event.target.result?.includes("AAC")) {
          data = event.target.result?.replace("AAC", "mpeg");
        }else if (event.target.result?.includes("mp4")) {
          data = event.target.result?.replace("mp4", "mpeg");
        }
        // const isSafari = /^((?!chrome|android).)*safari/i.test(
        //   navigator.userAgent
        // );

        // if (isSafari) {
        //   // Replace wav with mpeg for Safari
        //   data = event.target.result.replace("wav", "mpeg");
        // }
        pushAudio(data);
      };
      reader.readAsDataURL(fileInput);
    } else {
      // Handle case when fileInput is null
    }
  }
  var [sLang, setsLang] = useState("en");
  const pushAudio = (base) => {
    if (recorder.recordingTime > 1) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "_uads=a%3A2%3A%7Bs%3A4%3A%26quot%3Bdate%26quot%3B%3Bi%3A1692906205%3Bs%3A5%3A%26quot%3Buaid_%26quot%3B%3Ba%3A0%3A%7B%7D%7D; mode=night; src=1; user_id=11feaf01c8a0a60d73685aed9f29d0055654b7031692820141a226f6eb33e24c201535c4f106b5d4e9"
      );

      var languageCode = 'defined'; // xxxxx
      var langii = JSON.parse(localStorage.getItem('language')) // xxxxx
      
      if (langii === 'Spagnolo') {
        languageCode = 'es';
      } else if (langii === 'Italiano') {
        languageCode = 'it';
      }else if (langii === "English"){
        languageCode = 'en'
      }




      var raw = JSON.stringify({
        language:languageCode,
        gmt: "wefew",
        ip_address: ip,
        audio_base64: "" + base + "",
        device_id: deviceID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
    

      fetch(`https://${globalIP}/api/v1/uploadVoiceMessage`, requestOptions)
        .then((response) => {
          response.text();
          if (response.status == 200) {
            getVoice();
            msgFunc("message uploaded");
          }
        })
        .then((result) => {})
        .catch((error) => {
          msgFunc("Failed");
        });
    } else {
    }
  };

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
  };
  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(timerRef.current);
      setIsRunning(false);
    }
  };
  const resumeTimer = () => {
    if (!isRunning) {
      startTimer();
    }
  };
  const resetTimer = () => {
    clearInterval(timerRef.current);
    setIsRunning(false);
    setSeconds(0);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal1 = () => {
    setIsModalOpen1(true);
    setIsModalOpen(false);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
    getVoice();
  };
  const saveToLocal = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));
    var a = localStorage.getItem("lang");
    selectedLanguage = JSON.parse(a);
    setSelectedLanguage(JSON.parse(a));
    setLanguage(JSON.parse(localStorage.getItem("lang")))
  };

  const handleLanguageChange = (language) => {
    localStorage.setItem("language", JSON.stringify(language));
    setSelectedLanguage(language);
    selectedLanguage = language;
    closeModal1();

    saveToLocal(language);
    if (language === "Italiano") {
      setHeadingText("Ascolta\nContribuisci fai parte dell'arte globale registra un messaggio");
      // headingText = "Ascolta\nContribuisci fai parte dell'arte globale registra un messaggio"; // Commented out, as it's redundant
      setHeadingTextOne("Ascolta");
      setHeadingTextTwo("Contribuisci");
      setHeadingTextThree("fai parte dell'arte globale");
      setHeadingTextFour("registra un messaggio");
      
    } else if (language === "Spagnolo") {
      setHeadingText("Escuchar graba tu mensaje ¡Sé divertido sé divertido!");
      headingText = "Escuchar graba tu mensaje ¡Sé divertido sé divertido!";
      headingTextOne =
      "Escuchar";
    setHeadingTextOne(
      "Escuchar"
    );
    headingTextTwo =
      "contribuye";
    setHeadingTextTwo(
      "contribuye"
    );
    headingTextThree =
      "sé parte del arte global ";
    setHeadingTextThree(
      "sé parte del arte global "
    );
    headingTextFour =
    "grabando un mensaje ";
  setHeadingTextFour(
    "grabando un mensaje "
  );
    } else {
      setHeadingText("Listen\nContribute be part of global digital art record your message");
      headingText = "Listen\nContribute  be part of global digital art record your message";
      headingTextOne =
      "Listen";
    setHeadingTextOne(
      "Listen"
    );
    headingTextTwo =
      "Contribute";
    setHeadingTextTwo(
      "Contribute"
    );
    headingTextThree =
      "be part of global digital art";
    setHeadingTextThree(
      "be part of global digital art"
    );
    headingTextFour =
    "record your message";
  setHeadingTextFour(
    "record your message"
  );
    }
  };
  let [IsComingFromShareLink, setIsComming] = useState();
  let searchAudioId;
  function isComingFromSharedLink() {
    const urlParams = new URLSearchParams(window.location.search);
    const currentUrl = window.location.href;
    const idValue = urlParams.get("id");
    searchAudioId = idValue;
    if (urlParams.has("id")) {
      setIsComming(true);
      IsComingFromShareLink = true;
    } else {
      setIsComming(false);
      IsComingFromShareLink = false;
    }
  }
  const byCoffee = () => {
    window.location.href = "https://www.buymeacoffee.com/funfy";
  };
  const [isPlay, setIsPlay] = useState(false);
  const [isCurrentTime, setIsCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const pauseAudio = async (audio) => {
    await audio.pause();
  };
  const [isPaused, setIsPaused] = useState(false);
  const [pausedTime, setPausedTime] = useState(0);
  //temporary
  const audioRecorderRef = useRef(null);

  const audioPlay = (isPlayed) => {
    if (currentAudio != null) {
      const audio = document.getElementById("audioPlay");
      if (isPlayed) {
        const url = URL.createObjectURL(blob);

        if (audio.srcObject) {
          audio.srcObject = blob;
        } else {
          audio.src = url;
          audio.addEventListener("timeupdate", () => {
            setIsCurrentTime(audio.currentTime);
          });
        }

        // Attempt to play within a user interaction event
        document.addEventListener("click", function playAudio() {
          audio
            .play()
        
            .catch((error) => {
              // Autoplay was prevented
            });

          document.removeEventListener("click", playAudio); // Remove the listener after the first user interaction
        });
      } else {
        audio.pause();
       
        resetTimer();
      }

      audio.addEventListener("ended", () => {
        resetTimer();
        setIsPlay(false);
        
      });
    } else {
      alert("Current audio missing");
    }
  };

  const chnageButton = () => {
    if (isPlay) {
      setIsPlay(false);
      audioPlay(false);
    } else {
      setIsPlay(true);
      audioPlay(true);
    }
  };
  //   setTimeout(()=>{
  //   if (isPlay) {
  //     setIsPlay(false);
  //     audioPlay(false);
  //   } else {
  //     setIsPlay(true);
  //     audioPlay(true);
  //   }
  // },9000)
  const startPlayback = () => {
    recorder.togglePauseResume();
  };
  const onDrag = (a) => {
    setRecordBar(true);
    setLockBar(false);
    recorder.startRecording();
  };
  // const audioStartRecording = (a) => {
  //   setRecordBar(true);
  //   setLockBar(false);
  //   recorder.startRecording();
  //   await convertAudioToBase64(blob);
  // };
  const onLongPress = (e) => {
    setLockBar(true);
    recorder.startRecording();
  };
  const onLongPressEnd = async (d) => {
    setLockBar(false);
    recorder.stopRecording();
    await convertAudioToBase64(blob);
  };
  const [isRecording, setIsRecording] = useState(false); // State to manage recording
  const [audioBlobed, setAudioBlobed] = useState(null);

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

  const stopRecordingAndRetrieveBlob = () => {
    if (audioRecorderRef.current && isRecording) {
      audioRecorderRef.current.stopRecording(); // Stop recording through ref
    }
  };

  const audioStartRecording = async (d) => {
    if (isPlay) {
      setIsPlay(false);
      audioPlay(false);
    }

    setIsRecordingTwo(true);
    setIsPausedTwo(false);
    setRecordedBlobs([]);
    recorderControls.startRecording();
    recorder.startRecording();
    setRecordBar(true);
    setIsRecording(true);
    setLockBar(false);
    // console.log("recordingBlob", recorder.recordingBlob);
    // console.log('recorder',d)
    // await convertAudioToBase64(blob);
    // await convertAudioToBase64(newAudioBlob);
    await convertAudioToBase64(blob);
  };
  const handleRecording = (audioData) => {
    // xyz
   
    // Store blob data in state when available
    if (audioData && audioData.audio instanceof Blob) {
    }
  };
  //   const audioStopRecording = (recordedBlob) => {
  //     // Your code to stop recording
  //     setIsRecording(false); // Stop recording logic
  // console.log('recordedBlob',recordedBlob)

  //     if (audioBlobed) {
  //       const audioUrl = URL.createObjectURL(audioBlobed);
  //       const audio = new Audio(audioUrl);
  //       audio.play();
  //     }
  //   };
  // ismn ye do bar click krne se pher ye play horaha hai ese na ho

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isReplayAudioPlaying, setIsReplayAudioPlaying] = useState(false);

  const audioStopRecording = (recordedBlob) => {
    if (recordedBlobs.length === 0) return;

    const audio = audioRef.current;
    audio.src = window.URL.createObjectURL(
      new Blob(recordedBlobs, { type: "audio/webm" })
    );
    setIsAudioPlaying(true);
    audio.play();

    return;

    if (!isAudioPlaying) {
      setIsRecording(false);
      recorderControls.stopRecording();
      const test = recorderControls.recordingBlob;

      if (test && !isAudioPlaying) {
        const audioUrl = URL.createObjectURL(test);
        const audio = new Audio(audioUrl);

        audio.play();
        setIsAudioPlaying(true);

        audio.addEventListener("ended", () => {
          setIsAudioPlaying(false);
        });
      } else {
        setIsAudioPlaying(false);
      }
    } else {
      //
    }
  };

  const resumeRecording = () => {};
  const handlePlayback = () => {
    if (audioBlobed) {
      const audioUrl = URL.createObjectURL(audioBlobed);
      const audio = new Audio(audioUrl);
      audio.play();
    }
  };
  const autoplayAudio = (e) => {
    e.target.muted = false;
    setAutoplayCondition(true);
    startTimer();
    setIsPlay(true);
  };
  const autoEndAudio = () => {
    resetTimer();
    setAutoplayCondition(false);
    setIsPlay(false);
    getVoice();
    var b = (currentIndex += 1);
    setCurrentIndex(b);
  };

  const autoStartAudioReplay = () => {

    setIsReplayAudioPlaying(true);
  };
  const autoEndAudioReplay = () => {
    setIsReplayAudioPlaying(false);
    setIsAudioPlaying(false);
  };
  const [autoplayCondition, setAutoplayCondition2] = useState(false);

  useEffect(() => {
    setAutoplayCondition2(true); // Autoplay enable karna
  }, []);

  const handlePauseClick = () => {
    recorder.togglePauseResume();

    recorder.mediaRecorder.addEventListener("dataavailable", (d) => {
      // Store blob data in state when available
      if (d.data && d.data instanceof Blob) {
        // setBlobData(d.data);
      }
    });
  };

  const handleStartRecordingTwo = () => {
    setIsRecordingTwo(true);
    setIsPausedTwo(false);
    setRecordedBlobs([]);
  };

  const handleStopRecordingTwo = () => {
    setIsRecordingTwo(false);
    setIsPausedTwo(false);
  };

  const handlePauseRecording = () => {
    setIsRecordingTwo(false);
    setIsPausedTwo(true);
  };

  const handleResumeRecording = () => {
    setIsRecordingTwo(true);
    setIsPausedTwo(false);
  };

  const onData = (recordedBlob) => {
    setRecordedBlobs((prevBlobs) => [...prevBlobs, recordedBlob]);
  };

  const onStop = (recordedBlob) => {
    setRecordedBlobs((prevBlobs) => [...prevBlobs, recordedBlob]);
  };

  // const handleReload = () => {
  //   if (currentAudio) {
  //     setIsPlay(true);
  //     audioPlay(true);
  //   }
  // };
  return (
    <>
      <div className="homeMainCon">
        <div className="navBar">
          <div>
            <img className="navLogo" src={logo} alt="logo" />
          </div>
          {/* <button onClick={recorderControls.stopRecording}>testing</button> */}
          <div className="rightIcons">
            <RWebShare
              className="modalShare"
              data={{
                text: "Awseome Random Voices",
                url: `https://funfy.org?id=${currentAudioId}`,
                title: "Share Fun from Funfy 😉",
              }}
            >
              <img className="iconsNav" src={share} alt="share" />
            </RWebShare>

            <img
              onClick={byCoffee}
              className="iconsNav"
              src={coffee}
              alt="coffee"
            />
            <img
              onClick={openModal}
              className="iconsNav"
              src={more}
              alt="more"
            />
          </div>
        </div>
        <div></div>
        <div className="tagLineCon">
    <h4>{headingTextOne}<br />{headingTextTwo} <br />{headingTextThree} <br />{headingTextFour}</h4>
        </div>
       
       
        {noAudioFound == true ? (
          <>
            <div className="recordBar2">
              <div className="playBtn">
                <img className="audioBarIcons" src={play} alt="" />
              </div>
              <div className="vidualsPlay"></div>
              <div
                style={{ height: "100%", width: "5px", background: "#f4c615" }}
                className="break"
              ></div>
              <img
                onClick={() => {
                  indexchnager();
                }}
                className="audioBarIcons"
                src={forward}
                alt="next"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#46443f",
                justifyContent: "space-between",
                border: "none",
                position: "absolute",
                top: "70%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "60px",
                width: "200px",
                padding: "0px",
              }}
            >
              <p style={{ color: "white", textAlign: "center" }}>
                No Audios Here !
              </p>
            </div>
          </>
        ) : (
          <div className="recordBar">
           

            <div className="playBtn">
              {isPlay ? (
                <img
                  onClick={chnageButton}
                  className="audioBarIcons"
                  src={pause}
                  alt=""
                />
              ) : (
                <img
                  onClick={chnageButton}
                  className="audioBarIcons"
                  src={play}
                  alt=""
                />
              )}
            </div>
            <div className="vidualsPlay">
              {blob != null && AudioOn && (
                <AudioVisualizer
                  // id="audioPlay"
                  blob={blob}
                  currentTime={isCurrentTime}
                  barColor="#f4c61554"
                  barPlayedColor="#f4c615"
                  width={300}
                  style={{width:'100%'}}
                  height={50}
                />
              )}
            </div>

            <div className="timer">
              <h5>{formatTime(seconds)}</h5>
            </div>
            <div
              style={{ height: "100%", width: "5px", background: "#f4c615" }}
              className="break"
            ></div>
            <img
              onClick={() => {
                indexchnager();
              }}
              className="audioBarIcons"
              src={forward}
              alt="next"
            />
          </div>
        )}

{currentAudio && (
              <audio
                id="audioPlay"
                src={currentAudio}
                controls
                style={{
                  visibility: "hidden",
                  position: "absolute",
                }}
                playsInline
                onPlay={autoplayAudio}
                onEnded={autoEndAudio}
                autoPlay
              ></audio>
            )}
        
      </div>
      <div
        className="bottomCon"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <ReactMic
          record={isRecordingTwo}
          className="sound-wave"
          onStop={onStop}
          onData={onData}
          strokeColor="#000000"
          backgroundColor="#FF4081"
          pause={!isRecordingTwo && isPausedTwo}
        /> */}

        <audio
          ref={audioRef}
          controls
          hidden
          id="audioReply"
          onEnded={autoEndAudioReplay}
          onPlay={autoStartAudioReplay}
        />


        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}
        {/* ??????????????????????????????????????????????????????????????????????? */}

        <div
          style={{
            position: "absolute",
            left: "48px",
            display: !lockBar ? "none" : "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: !lockBar ? "none" : "flex",

              width: "50px",
              height: "50px",
              background: "#e45829",
              borderRadius: "100px",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <h4 style={{ color: "white" }}>REC</h4>
          </div>
          <h4 style={{ marginLeft: "10px", width: "60px" }}>
            {recorder.recordingTime < 60
              ? `00:${recorder.recordingTime < 10 ? "0" : ""}${
                  recorder.recordingTime
                }`
              : `${Math.floor(recorder.recordingTime / 60)}:${
                  recorder.recordingTime % 60 < 10 ? "0" : ""
                }${recorder.recordingTime % 60}`}
          </h4>
          <h5 style={{ marginLeft: "40px" }}>Scroll to cancel</h5>
          <h5 style={{ marginLeft: "40px" }}>&lt;</h5>
        </div>
        <img
          onClick={() => {
            reportAudio();
            msgFunc("Abuse Reported");
          }}
          style={{
            marginBottom: "5px",
            display: lockBar ? "none" : "block",
            position: "absolute",
            right: "48px",
            width: "50px",
            cursor: "pointer",
          }}
          src={reportIcon}
          alt="report"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: "50px",
            padding: "5px",
            height: lockBar ? "200px" : "",
            background: lockBar ? "#e45829" : "",
            position: "absolute",
            left: "48px",
            borderRadius: "20px",
          }}
        >
          <DropTarget targetKey="foo">
            <img
              style={{
                display: lockBar ? "block" : "none",
              }}
              className="lockBarImg"
              src={lock}
              alt=""
            />
          </DropTarget>
          <h2
            style={{
              transform: "rotate(90deg)",
              color: "yellow",
              display: lockBar ? "block" : "none",
            }}
          >
            &lt;
          </h2>
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <AudioRecorder
              ref={audioRecorderRef}
              start={isRecording}
              onRecordingComplete={handleRecording}
              // onRecordingComplete={(blob) => addAudioElement(blob)}
              audioStartRecording={audioStartRecording}
              handleAudioStop={(blob) => audioStopRecording(blob)}
              recorderControls={recorderControls}
              togglePauseResume={() => resumeRecording()}
              style={{
                display: "none",
              }}
            />
            <DragDropContainer targetKey="foo">
              <img
                style={{
                  width: "50px",
                }}
                src={lockBar ? mic : recordIcon}
                alt="record"
                draggable="true"
                onClick={audioStartRecording}
              />
            </DragDropContainer>
          </div>
        </div>
      </div>
      <div
        className="lockAudio"
        style={{
          display: recordBar ? "flex" : "none",
          background: "#e45829",
          height: "250px",
        }}
      >
        <p
          style={{
            color: "#f4c615",
            textAlign: "center",
          }}
        >
          you are now recording your max{" "}
          <span style={{ fontWeight: "bold" }}>{limitTime}</span> seconds voice
          message
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "25%",
            marginTop: "0px",
          }}
        >
          <div
            style={{
              width: "15%",
              display: "flex",
              marginTop: "-19px",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <h5 style={{ color: "#f4c615" }}>
              {recorder.recordingTime < 60
                ? `00:${recorder.recordingTime < 10 ? "0" : ""}${
                    recorder.recordingTime
                  }`
                : `${Math.floor(recorder.recordingTime / 60)}:${
                    recorder.recordingTime % 60 < 10 ? "0" : ""
                  }${recorder.recordingTime % 60}`}
            </h5>
          </div>
          <div style={{ width: "80%" }}>
            {recorder.isRecording ? (
              recorder.mediaRecorder && (
                <LiveAudioVisualizer
                  className="audioVisuals"
                  mediaRecorder={recorder.mediaRecorder}
                  barColor="#f4c615"
                  barWidth={7}
                  height={20}
                  width={400}
                />
              )
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-between",
            width: "90%",
            height: "60%",
            margin: "10px auto",
          }}
        >
          {recordIconCondition == true ? (
            <div
              className="iconCircle"
              style={{
                width: "30px",
                height: "30px",
              }}
            >
              <img
                onClick={() => {
                  recorder.stopRecording();
                  setRecordBar(false);
                  setLockBar(false);
                  setRecordIconCondition(!recordIconCondition);
                  setIsRecording(false);
                  setTimeLimitCrossed(false);
                }}
                className="lockBaricons"
                src={bin}
                alt="Cancel"
              />
            </div>
          ) : (
            <div></div>
          )}
          {isTimeLimitCrossed == true ? (
            <div
              className="iconCircle"
              style={{
                width: "40px",
                height: "40px",
              }}
            >
              <img
                className="lockBaricons"
                src={recordIcon3}
                alt="Cancel"
                style={{
                  width: "40px",
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                recorder.togglePauseResume();
                setRecordIconCondition(!recordIconCondition);
                setIsRecordingTwo(!isRecordingTwo);
                setIsPausedTwo(!isPausedTwo);
              }}
              className="iconCircle"
              style={{
                width: "40px",
                height: "40px",
              }}
            >
              <img
                className="lockBaricons"
                src={recordIconCondition == true ? recordIcon2 : blinking}
                alt="Cancel"
                style={{
                  width: "40px",
                }}
              />
            </div>
          )}

          {recordIconCondition == true ? (
            <div
              className="iconCircle"
              style={{
                width: "30px",
                height: "30px",
              }}
            >
              <img
                className="lockBaricons"
                onClick={audioStopRecording}
                src={isAudioPlaying ? Pause : send}
                alt="Cancel"
                style={{
                  width: "30px",
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            width: "90%",
            height: "60%",
            margin: "10px auto",
          }}
        >
          {recordIconCondition == true ? (
            <button
              style={{
                borderRadius: "5px",
                padding: "5px 0px",
                width: "120px",
                backgroundColor: "#f4c615",
                color: "#e45829",
                fontSize: "18px",
                border: "none",
              }}

              onClick={() => {
                recorder.stopRecording();
                setRecordBar(false);
                setLockBar(false);
                const audio = audioRef.current;
                 audio.pause();
                 setIsAudioPlaying(false);
                setRecordIconCondition(!recordIconCondition);
                recorder.mediaRecorder.addEventListener(
                  "dataavailable",
                  (d) => {
                    
                    convertAudioToBase64(d.data);
                    setTimeLimitCrossed(false);
                    getVoice()
                    setDuration(
                      `${
                        recorder.recordingTime < 60
                          ? `00:${recorder.recordingTime < 10 ? "0" : ""}${
                              recorder.recordingTime
                            }`
                          : `${Math.floor(recorder.recordingTime / 60)}:${
                              recorder.recordingTime % 60 < 10 ? "0" : ""
                            }${recorder.recordingTime % 60}`
                      }`
                    );
                  }
                );
              }}
            >
              Send
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModalAlert}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "80px",
              width: "230px",
            },
          }}
        >
          <p style={{ color: "white", textAlign: "center" }}>
            Before to start recording please allow your microphone
          </p>
        </Modal>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "250px",
              width: "200px",
            },
          }}
        >
          <Link className="reactLink" to="/about-us">
            <button className="btnLang">
              {selectedLanguage === "About Us" ? "About Us" : "About Us"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <Link className="reactLink" to="/support">
            <button className="btnLang">
              {selectedLanguage === "Support Funfy"
                ? "Support Funfy"
                : "Support Funfy"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <Link className="reactLink" to="/privacy">
            <button className="btnLang">
              {selectedLanguage === "Privacy" ? "Privacy" : "Privacy"}
            </button>
          </Link>
          <div className="btnLine"></div>

          <Link className="reactLink" to="/terms">
            <button className="btnLang">
              {selectedLanguage === "Terms & Conditions"
                ? "Terms & Conditions"
                : "Terms & Conditions"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => {
              openModal1();
            }}
          >
            {selectedLanguage === "Change Language"
              ? "Change Language"
              : "Change Language"}
          </button>
        </Modal>
        <Modal
          isOpen={isModalOpen1}
          onRequestClose={closeModal1}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              width: "200px",
            },
          }}
        >
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("English")}
          >
            {selectedLanguage === "English" ? "English" : "English"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Italiano")}
          >
            {selectedLanguage === "Italiano" ? "Italiano" : "Italiano"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Spagnolo")}
          >
            {selectedLanguage === "Spagnolo" ? "Spagnolo" : "Spagnolo"}
          </button>
        </Modal>
        <div
          className="mainAboutCon msgCustom"
          style={{
            position: "absolute",

            display: !msgShow ? "none" : "flex",
            bottom: 10,
            left: "46%",
          }}
        >
          <div
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
              background: "#4b4b4b",
              height: "40px",
              fontSize: "15px",
              // width: "170px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            {alertMsg}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
