import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import coffee from "../Assets/coffee.png";
import logo from "../Assets/logo.png";
import more from "../Assets/menu.png";
import share from "../Assets/share.png";
import { globalIP } from "../Ip_address";

function About() {
  const navigate = useNavigate();
  let [aboutusText, setAboutUsText] = useState();
  let [isModalOpen1, setIsModalOpen1] = useState(false);
  let [selectedLanguage, setSelectedLanguage] = useState("English");

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const openModal1 = () => {
    setIsModalOpen1(true);
    setIsModalOpen(false);
  };

  const saveToLocal = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang));
    var a = localStorage.getItem("lang");
    setSelectedLanguage(a);
    selectedLanguage = a;
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      var a = localStorage.getItem("lang");
      setSelectedLanguage(a);
      selectedLanguage = a;
      getData(selectedLanguage);
    }
    return () => {
      ignore = true;
    };
  }, []);

  const getData = async (currentLang) => {
    await axios
      .get(`https://${globalIP}/api/v1/getAppConfig`)
      .then(function (response) {
        console.log(response.data.config);
        if (currentLang.includes("Spagnolo")) {
          aboutusText = `${response.data.config[6]["value"].replaceAll(
            "<[^>]*>",
            ""
          )}`; // it
          setAboutUsText(
            `${response.data.config[6]["value"].replaceAll("<[^>]*>", "")}`
          ); // es
        } else if (currentLang.includes("Italiano")) {
          aboutusText = `${response.data.config[5]["value"].replaceAll(
            "<[^>]*>",
            ""
          )}`; // it
          setAboutUsText(
            `${response.data.config[5]["value"].replaceAll("<[^>]*>", "")}`
          );
        } else {
          aboutusText = `${response.data.config[1]["value"].replaceAll(
            "<[^>]*>",
            ""
          )}`;
          setAboutUsText(
            `${response.data.config[1]["value"].replaceAll("<[^>]*>", "")}`
          );
        }
      })
      .catch(function (error) {
        Swal.fire({
          customClass: 'alertCss',
          title: "Network Error!",
    
          icon: "error",
        });
      })
      .finally(function () {});

    // console.log(privacyText);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const byCoffee = () => {
    window.location.href = "https://www.buymeacoffee.com/funfy";
  };
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    closeModal1();
    saveToLocal(language);
    getData(selectedLanguage);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="mainAboutCon"  style={{ background: "#f4c615", height: "100vh", }}>
      <div className="navBar">
        <div
          onClick={() => {
            navigate("/home");
          }}
        >
          <img className="navLogo" src={logo} alt="logo" />
        </div>
        <div className="rightIcons">
          <img className="iconsNav" src={share} alt="share" />
          <img
            onClick={byCoffee}
            className="iconsNav"
            src={coffee}
            alt="coffee"
          />
          <img onClick={openModal} className="iconsNav" src={more} alt="more" />
        </div>
      </div>
      <div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "250px",
              width: "200px",
            },
          }}
        >
          <Link className="reactLink" to="/about-us">
            <button className="btnLang">
              {selectedLanguage === "About Us" ? "About Us" : "About Us"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <Link className="reactLink" to="/support">
            <button className="btnLang">
              {selectedLanguage === "Support Funfy"
                ? "Support Funfy"
                : "Support Funfy"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <Link className="reactLink" to="/privacy">
            <button className="btnLang">
              {selectedLanguage === "Privacy" ? "Privacy" : "Privacy"}
            </button>
          </Link>
          <div className="btnLine"></div>

          <Link className="reactLink" to="/terms">
            <button className="btnLang">
              {selectedLanguage === "Terms & Conditions"
                ? "Terms & Conditions"
                : "Terms & Conditions"}
            </button>
          </Link>
          <div className="btnLine"></div>
          <button
            onClick={() => {
              openModal1();
            }}
            className="btnLang"
          >
            {selectedLanguage === "Change Language"
              ? "Change Language"
              : "Change Language"}
          </button>
        </Modal>
        <Modal
          isOpen={isModalOpen1}
          onRequestClose={closeModal1}
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            overlay: {
              backgroundColor: "transparent",
              zIndex: 100,
            },
            content: {
              borderRadius: "30px",
              display: "flex",
              flexDirection: "column",
              background: "#46443f",
              justifyContent: "space-between",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              width: "200px",
            },
          }}
        >
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("English")}
          >
            {selectedLanguage === "English" ? "English" : "English"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Italiano")}
          >
            {selectedLanguage === "Italiano" ? "Italiano" : "Italiano"}
          </button>
          <div className="btnLine"></div>
          <button
            className="btnLang"
            onClick={() => handleLanguageChange("Spagnolo")}
          >
            {selectedLanguage === "Spagnolo" ? "Spagnolo" : "Spagnolo"}
          </button>
        </Modal>
      </div>
      <div
        style={{
          width: "100%",
          background: "#e45829",
          height: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: "white",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <h4>About Us</h4>
      </div>
        <div style={{overflowY:'auto',height:"82vh"}}>
        <h5 style={{ fontSize: "18px", fontWeight: "400", padding: "20px",marginTop:'0' }}>
    {aboutusText}
  </h5>
        </div>
      <div className="mainAboutCon"
        style={{
          position: "absolute",
          bottom: "10px",
          //   background: "red",
          height: "40px",
        }}
      >
        <div
          onClick={() => navigate('/home')}
          style={{
            margin: "0px auto",

            height: "40px",
            width: "90px",
            background: "#298fb2",
            color: "#f4c615",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          Back
        </div>
      </div>
    </div>
  );
}

export default About;
