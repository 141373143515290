import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import HomePage from "./Pages/HomePage";
import Privacy from "./Pages/Privacy";
import Support from "./Pages/Support";
import Terms from "./Pages/Terms";
import WelcomePage from "./Pages/WelcomePage";
import Main from './Pages/Main';



const SplashScreen = () => {
  const navigate = useNavigate();
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setShowSplash(false);
      navigate('/wellcome'); 
    }, 1600); // Set the timeout to half of your desired time

    return () => clearTimeout(splashTimer);
  }, [navigate]);

  return (
    <div className={showSplash ? 'splash-screen' : 'splash-screen-hidden'}>
      <h1 style={{ color: showSplash ? 'white' : 'black' }}>corvisieri develops</h1>
    </div>
  );
};




function App() {
  return (
      <>
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Main />}></Route>
        </Routes>
      </BrowserRouter>
    <div className="mainHomeContianer"  >
      <BrowserRouter>
        <Routes >
          <Route path="/wellcome" element={<WelcomePage />}></Route>
          <Route path="/home" element={<HomePage />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
      </>
  );
}

export default App;
